import React from "react";

import Button from "../components/Button";
import Section from "../components/Section";
import ClassesSlider from "../components/ClassesSlider";

const ClassesSection = (props) => {
  return (
    <Section
      title="Check out some of our classes"
      id="classes"
      container="full"
      className="uk-text-center uk-section-large"
    >
      <ClassesSlider classes={props.classes} />      
      <Button color="secondary">See all available classes</Button>
    </Section>
  );
};

export default ClassesSection