import React from "react"
import { Link } from "react-router-dom"

const Footer = props => {
  return (
    <footer
      className="uk-section uk-section-secondary uk-padding-remove-bottom"
      id="site-footer"
    >
      <div className="uk-container">
        <div className="uk-grid uk-grid-large" data-uk-grid>
          <div className="uk-width-1-2@m">
            <h5>OUR COMPANY</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud cillum dolore eu fugiat nulla
              contact to:{" "}
              <a href="/" title="">
                info@company.com
              </a>
            </p>
            <div>
              <a
                href="https://twitter.com"
                className="uk-icon-button"
                data-uk-icon="twitter"
              ></a>
              <a
                href="https://facebook.com"
                className="uk-icon-button"
                data-uk-icon="facebook"
              ></a>
              <a
                href="https://instagram.com"
                className="uk-icon-button"
                data-uk-icon="instagram"
              ></a>
            </div>
          </div>
          <div className="uk-width-1-6@m">
            <h5>PRODUCTS</h5>
            <ul className="uk-list">
              <li>
                <Link to="/">Big Data</Link>
              </li>
              <li>
                <Link to="/">Marketing</Link>
              </li>
              <li>
                <Link to="/">Analytics</Link>
              </li>
              <li>
                <Link to="/">AI Lab</Link>
              </li>
            </ul>
          </div>
          <div className="uk-width-1-6@m">
            <h5>OUR COMPANY</h5>
            <ul className="uk-list">
              <li>
                <Link to="/">Team</Link>
              </li>
              <li>
                <Link to="/">Work</Link>
              </li>
              <li>
                <Link to="/">Culture</Link>
              </li>
              <li>
                <Link to="/">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="uk-width-1-6@m">
            <h5>OUR OFFICES</h5>
            <ul className="uk-list">
              <li>
                <Link to="/">London</Link>
              </li>
              <li>
                <Link to="/">Chicago</Link>
              </li>
              <li>
                <Link to="/">Dubai</Link>
              </li>
              <li>
                <Link to="/">Brussels</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
        <span className="uk-text-small uk-text-muted">
          © 2020 - <a href="https://tomungerer.com/">Created by Tom Ungerer</a>{" "}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
