import React from 'react'
import {Link} from 'react-router-dom'

import './Class.scss'

const Class = ({ item }) => {
  const { id, title, video, category, excerpt, level } = item
  return (
    <div className="class uk-card uk-card-default" style={{ margin: "1rem" }}>
      <header className="uk-card-media-top uk-inline uk-light">
        <img src={video.url} alt="video" />
        <div className="uk-overlay uk-position-center">
          <p><Link
            to={`/class/${id}`}
            className="uk-icon-button"
            data-uk-icon="icon: play; ratio: 3"
          /></p>
        </div>
      </header>
      <main className="uk-card-body">
        <h3 className="title uk-card-title">{title}</h3>
        <div className="category">{category}</div>
        <p>{excerpt}</p>
      </main>
      <footer className="uk-card-footer">
        <div className="uk-flex-between" data-uk-grid>
          <div className="level">
            <strong>
              Level <span>{level}</span>
            </strong>
          </div>
          <div className="duration">
            <strong>Duration:</strong> <span>{video.duration}</span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Class