import React from "react";

import Class from "./Class";

const ClassesSlider = (props) => {
  return (
    <div data-uk-slider="center: true">
      <div
        className="uk-margin-large uk-position-relative uk-visible-toggle"
        tabIndex="-1"
        data-uk-match-height="target: .uk-card-body"
      >
        <ul className="uk-slider-items uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match">
          {props.classes.map((item) => (
            <li key={`class_${item.id}`}>
              <Class item={item} />
            </li>
          ))}
        </ul>
        <button
          className="uk-position-center-left uk-button"
          data-uk-slidenav-previous
          data-uk-slider-item="previous"
        ></button>
        <button
          className="uk-position-center-right uk-button"
          data-uk-slidenav-next
          data-uk-slider-item="next"
        ></button>
      </div>
    </div>
  );
};

export default ClassesSlider