import React from 'react'

import { Link } from 'react-router-dom'

import './Button.scss'

const Button = props => {
  const { color, path } = props
  const newPath = path
  ? path
  : '/'
  return (
    <Link to={newPath} className={`uk-button uk-button-${color ? color : 'default'}`}>
      {props.children}
    </Link>
  );
}

export default Button